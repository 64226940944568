export type ApiDocProps = {
  children: string;
  api?: string;
};
const markdownRefStyle = {
  display: 'inline-block',
  verticalAlign: 'middle',
  lineHeight: 1,
  padding: '0.2em',
  backgroundColor: '#44475a',
  color: 'rgba(248, 248, 242)',
  fontSize: '14px',
  borderRadius: '3px',
  fontFeatureSettings: `'clig' 0,'calt' 0`,
  fontVariant:
    'no-common-ligatures no-discretionary-ligatures no-historical-ligatures no-contextual',
};
export const ApiDoc = ({ children, api }: ApiDocProps) => {
  const apiHref = api || children;
  return (
    <a
      style={{
        textDecoration: 'none',
        ...markdownRefStyle,
      }}
      href={`/edge.html#${apiHref}`}
      target="_blank"
      title={`${apiHref} - Api Reference`}
    >
      <span style={{ color: '#e75480' }}>T&nbsp;</span>
      {children}
    </a>
  );
};
