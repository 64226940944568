export const Deprecation = ({ children }) => (
  <div
    style={{
      pointerEvents: 'none',
      userSelect: 'none',
      opacity: 0.2,
    }}
  >
    {children}
  </div>
);
