import { useEffect, useState } from 'react';
import { Language } from 'prism-react-renderer';

// https://github.com/jpedroschmitz/rocketdocs/blob/main/%40rocketseat/gatsby-theme-docs/src/components/Code/index.js
import CodeHighlight from '@rocketseat/gatsby-theme-docs/src/components/Code';
import { useConfigId } from './hooks';

export const PortalAwareCodeblock = ({
  code,
  language,
}: {
  code: string;
  language?: Language;
}) => {
  const configId = useConfigId();

  const onScreen = code.replace('CONFIG_ID', configId);
  return (
    <CodeHighlight
      live={false}
      className={`language-${language || 'html'}`}
      codeString={onScreen}
    ></CodeHighlight>
  );
};
