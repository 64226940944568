import { useEffect, useState } from 'react';

const nullConfigId = 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx';

export const useConfigId = () => {
  let [configId, setConfigId] = useState(nullConfigId);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const maybeCfgId = params.get('cfg');
    if (
      /^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/.test(
        maybeCfgId
      )
    ) {
      setConfigId(params.get('cfg'));
    }
  }, []);

  return configId;
};

export const useHasConfigId = () => useConfigId() !== nullConfigId;
